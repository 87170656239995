<template>
  <div class="">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TheScorecard",
  data() {
    return {};
  },
  computed: {
    userMarshallGroups() {
      return this.$store.getters.marshallGroups;
    },
    userScorecards() {
      return this.$store.getters.userScorecards;
    },
  },
  watch: {
    $route: function () {
      if (this.$router.currentRoute.name == "scorecard") {
        this.redirect();
      }
    },
    userScorecards: function () {
      if (this.$router.currentRoute.name == "scorecard") {
        this.redirect();
      }
    },
  },
  methods: {
    redirect() {
      if (this.userMarshallGroups.length + this.userScorecards.length > 1) {
        this.$router.push({ name: "scorecard-picker" });
      } else if (
        this.userMarshallGroups.length + this.userScorecards.length ==
        1
      ) {
        if (this.userMarshallGroups.length > 0) {
          this.$router.push({
            name: "marker",
            params: { groupId: this.userMarshallGroups[0].id },
          });
        } else {
          this.$router.push({
            name: "marker",
            params: { groupId: this.userScorecards[0].id },
          });
        }
      } else {
        this.$router.push({ name: "home" });
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute.name == "scorecard") {
      this.redirect();
    }
  },
  beforeMount() {
    this.$store.dispatch("setMainNavState", false);
    this.$store.dispatch("setFooterState", false);
  },
  beforeDestroy() {
    this.$store.dispatch("setMainNavState", true);
    this.$store.dispatch("setFooterState", true);
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
</style>
